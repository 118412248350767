import React from 'react';
import './index.scss';

import {
  Button,
  Navbar,
  NavbarDivider,
  NavbarGroup
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { LinkButton } from '../LinkButton';
import { Alignment } from '@blueprintjs/core/lib/esm/common/alignment';
import { useRecoilState, useRecoilValue } from 'recoil';
import { apiKeyStateAtom, authStateAtom } from '../../store';
import { storage } from '../../services';
import { useMsal } from '@azure/msal-react';

export default function Header(props: any) {
  const { instance } = useMsal();
  const authState = useRecoilValue(authStateAtom);
  const [apiKeyState, setApiKey] = useRecoilState(apiKeyStateAtom);
  const { user } = authState;
  const styles: React.CSSProperties = { fontSize: '18px' };
  let fullName = '';
  if (user) {
    let { name } = user;
    const [lastName, firstName] = name.split(', ');

    fullName = `${firstName || ''} ${lastName || ''}`;
  }

  const signInGroup = () => {
    if (!apiKeyState) {
      return (
        <NavbarGroup align={Alignment.RIGHT}>
          <>
            <span style={styles}>{fullName}</span>
            <NavbarDivider />
          </>
          <LinkButton icon={IconNames.LOG_IN} minimal={true}>
            Login
          </LinkButton>
        </NavbarGroup>
      );
    }

    return (
      <NavbarGroup align={Alignment.RIGHT}>
        <>
          <span style={styles}>{fullName}</span>
          <NavbarDivider />
        </>
        <Button
          icon={IconNames.LOG_OUT}
          onClick={() => {
            // alert('foo')
            // storage.set('dhcApiKey', '');
            storage.clear();
            // auth.signOut();
            instance.logoutRedirect().catch((e) => {
              // eslint-disable-next-line no-console
              console.error(e);
            });
            setApiKey(undefined);
          }}
          minimal={true}
          title={'Logout'}
        >
          Logout
        </Button>
      </NavbarGroup>
    );
  };

  return (
    <Navbar className="Nav" fixedToTop>
      <NavbarGroup>
        <LinkButton to="/kits" icon={IconNames.BRIEFCASE} minimal={true}>
          Kits
        </LinkButton>
      </NavbarGroup>
      {signInGroup()}
    </Navbar>
  );
}
