import { atom } from 'recoil';
import { IRegisterDeviceDialog } from '../types';

export const registerDeviceDialogStateAtom = atom({
  key: 'registerDeviceDialogState',
  default: {
    firmwareVersion: '',
    serialNo: '',
    pcbRevision: 'UNKNOWN',
    isOpen: false
  } as IRegisterDeviceDialog
});
