import React, { Fragment } from 'react';
import './style.scss';
import { useRecoilState } from 'recoil';
import { paginationStateAtom } from '../../store/pagination-data';
import { IPagination } from '../../types/pagination';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { fetchPageNumbers, LEFT_PAGE, RIGHT_PAGE } from './PaginationUtils';

export const Pagination: React.FunctionComponent = () => {
  const [paginationState, setPaginationState] = useRecoilState(
    paginationStateAtom
  );
  const pageSizeOptions: number[] = [
    5,
    10,
    15,
    25,
    50,
    100,
    250,
    500,
    1000,
    10000
  ];

  const gotoPage = (page: any) => {
    const currentPage = Math.max(
      0,
      Math.min(
        page,
        Math.ceil(
          paginationState.filteredItems.length / paginationState.pageLimit
        )
      )
    );
    const offset = (currentPage - 1) * paginationState.pageLimit;
    const currentItems = paginationState.filteredItems.slice(
      offset,
      offset + paginationState.pageLimit
    );
    const pageNumbers = fetchPageNumbers(
      Math.ceil(
        paginationState.filteredItems.length / paginationState.pageLimit
      ),
      currentPage,
      1
    );
    const pagination: IPagination = {
      allItems: paginationState.allItems,
      filteredItems: paginationState.filteredItems,
      currentItems: currentItems,
      currentPage: currentPage,
      totalPages: paginationState.totalPages,
      pageLimit: paginationState.pageLimit,
      pageNeighbors: paginationState.pageNeighbors,
      pageNumbers: pageNumbers
    };
    setPaginationState(pagination);
  };

  const handleClick = (page: any) => (evt: any) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt: any) => {
    evt.preventDefault();
    gotoPage(
      paginationState.currentPage - paginationState.pageNeighbors * 2 - 1
    );
  };

  const handleMoveRight = (evt: any) => {
    evt.preventDefault();
    gotoPage(
      paginationState.currentPage + paginationState.pageNeighbors * 2 + 1
    );
  };

  const onPageSizeChange = () => {
    let selectElement: HTMLTextAreaElement = document.getElementById(
      'select_id'
    ) as HTMLTextAreaElement;
    if (selectElement != null) {
      let pageLimit: number = +selectElement.value;
      const currentPage = 1; //Math.max(0, Math.min(paginationState.currentPage, Math.ceil(paginationState.filteredItems.length / paginationState.pageLimit)));
      const offset = (currentPage - 1) * pageLimit;
      const currentItems = paginationState.filteredItems.slice(
        offset,
        offset + pageLimit
      );
      const pageNumbers = fetchPageNumbers(
        Math.ceil(paginationState.filteredItems.length / pageLimit),
        currentPage,
        1
      );
      const pagination: IPagination = {
        allItems: paginationState.allItems,
        filteredItems: paginationState.filteredItems,
        currentItems: currentItems,
        currentPage: currentPage,
        totalPages: paginationState.totalPages,
        pageLimit: pageLimit,
        pageNeighbors: paginationState.pageNeighbors,
        pageNumbers: pageNumbers
      };
      setPaginationState(pagination);
    }
  };

  return (
    <Fragment>
      <nav aria-label="Countries Pagination">
        <ButtonGroup className="bp3-button-group .modifier">
          {paginationState.pageNumbers.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <Button key={index} onClick={handleMoveLeft}>
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </Button>
              );

            if (page === RIGHT_PAGE)
              return (
                <Button key={index} onClick={handleMoveRight}>
                  <span className="sr-only">Next</span>
                  <span aria-hidden="true">&raquo;</span>
                </Button>
              );

            return (
              <Button
                className={paginationState.currentPage === page ? 'active' : ''}
                key={index}
                onClick={handleClick(page)}
              >
                {page}
              </Button>
            );
          })}
        </ButtonGroup>
        <span> &nbsp; &nbsp;</span>
        <span>Number of rows: </span>
        <select
          onChange={onPageSizeChange}
          id="select_id"
          value={paginationState.pageLimit}
        >
          {pageSizeOptions.map((option, index) => {
            return (
              <option key={`option${index}`} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      </nav>
    </Fragment>
  );
};
