import { storage } from './storage';

const mode = 'cors';
const credentials: RequestCredentials = 'omit';
const { mdhApiUrl, logisticsApiUrl } = window.CONFIG_ENV;

export class HttpAPI {
  private readonly prefix: string = '/';
  private apiKey?: string;
  private token?: string;

  constructor(prefix: string = '/', apiKey?: string) {
    if (!prefix.endsWith('/', prefix.length - 2)) {
      prefix += '/';
    }
    this.prefix = prefix;
    this.apiKey = apiKey;
    this.token = undefined;
  }

  setApiKey(apiKey: string) {
    this.apiKey = apiKey;
    return this;
  }

  setToken(token: string) {
    this.token = token;
    return this;
  }

  async url(svc: string): Promise<string> {
    if (svc.startsWith('http://') || svc.startsWith('https://')) {
      return svc;
    }

    svc = svc ? svc : '';
    if (svc.indexOf('/') === 0) {
      svc = svc.substr(1, svc.length - 1);
    }
    return this.prefix + svc;
  }

  headers(): Headers {
    const headers: any = { 'Content-Type': 'application/json' };

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`;
    } else if (this.apiKey) {
      headers['x-api-key'] = `${this.apiKey}`;
    }

    return headers;
  }

  init(method = 'GET', body: string | undefined = undefined): RequestInit {
    return {
      mode,
      method,
      credentials,
      body,
      headers: this.headers()
    };
  }

  async handler<T>(fr: Promise<Response>): Promise<T> {
    return fr
      .then(async (r) => {
        if (!r.ok) {
          console.log('Response is not okay', r);
        }
        if (r.status === 401) {
          //
        }
        const json = await r.json();
        if (json.error) {
          throw new Error(json.error);
        } else {
          if (!r.ok) {
            throw new Error(json.message || json.errorMessage || r.statusText);
          }
        }
        return json as T;
      })
      .catch((e: Error) => {
        console.log(e);
        throw e;
      });
  }

  async get<T>(path: string): Promise<T> {
    console.log(path);
    return this.handler<T>(fetch(await this.url(path), this.init()));
  }

  async post<T>(path: string, params: any): Promise<T> {
    return this.handler<T>(
      fetch(await this.url(path), this.init('POST', JSON.stringify(params)))
    );
  }

  async put<T>(path: string, params: any): Promise<T> {
    return this.handler<T>(
      fetch(await this.url(path), this.init('PUT', JSON.stringify(params)))
    );
  }

  async patch<T>(path: string, params: any): Promise<T> {
    return this.handler<T>(
      fetch(await this.url(path), this.init('PATCH', JSON.stringify(params)))
    );
  }

  async delete<T>(path: string): Promise<T> {
    return this.handler<T>(fetch(await this.url(path), this.init('DELETE')));
  }
}

export const api = new HttpAPI(logisticsApiUrl, storage.get('dhcApiKey'));

export const apiWithToken = new HttpAPI(
  logisticsApiUrl,
  storage.get('jwtToken')
);

export const mdhApiWithToken = new HttpAPI(mdhApiUrl, storage.get('jwtToken'));
