import { atom } from 'recoil';
import { IPagination } from '../types/pagination';

export const auditPaginationStateAtom = atom({
  key: 'auditsPagination',
  default: {
    allItems: [],
    filteredItems: [],
    currentItems: [],
    currentPage: 0,
    totalPages: 0,
    pageLimit: 5,
    pageNeighbors: 0,
    pageNumbers: []
  } as IPagination
});
