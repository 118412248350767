import { atom } from 'recoil';
import { IPrepareKitDialog } from '../types';

export const prepareKitDialogStateAtom = atom({
  key: 'prepareKitDialog',
  default: {
    deviceId: '',
    baseStationId: '',
    simId: '',
    isOpen: false
  } as IPrepareKitDialog
});
