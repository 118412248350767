import { atom } from 'recoil';
import { IDeviceInformationDialog } from '../types';

export const deviceInformationDialogStateAtom = atom({
  key: 'deviceInformationDialog',
  default: {
    deviceId: '',
    deviceStatus: '',
    deviceKey: '',
    createdAt: '',
    updatedAt: '',
    isOpen: false
  } as IDeviceInformationDialog
});
