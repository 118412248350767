import {atom} from 'recoil';
import {IPagination} from "../types/pagination";

export const paginationStateAtom = atom({
  key: 'pagination',
  default: {
    allItems: [],
    filteredItems: [],
    currentItems: [],
    currentPage: 0,
    totalPages: 0,
    pageLimit: 10,
    pageNeighbors: 0,
    pageNumbers: []
  } as IPagination
});
