import { IAuthState } from '../types';
import { Auth as AmplifyAuth } from '@aws-amplify/auth';
import { HttpAPI } from './api';
import { IJwtTokenResponse } from '../types/jwt-token-response';
import { ucFirst } from './formatters';
import { storage } from './storage';

const {
  userPoolId,
  userPoolWebClientId,
  identityPoolId,
  domain,
  authApiUrl,
  authUiUrl
} = window.CONFIG_ENV;

class Auth {
  constructor() {
    AmplifyAuth.configure({
      userPoolId,
      userPoolWebClientId,
      identityPoolId,
      cookieStorage: {
        domain,
        path: '/',
        expires: 365,
        secure: false
      }
    });
  }

  getDefaultState(): IAuthState {
    return {
      user: undefined
    };
  }

  parseToken(token: string) {
    if (!token) {
      return;
    }

    const [, data] = token.split('.', 3);
    return JSON.parse(atob(data));
  }

  extractNameFromEmail(email: string) {
    const [fullName] = email.split('@', 2);
    return fullName.split('.').map(ucFirst).join(' ');
  }

  public async getAuthorizationToken(
    token: string
  ): Promise<IJwtTokenResponse | undefined> {
    const authApi = new HttpAPI(authApiUrl);
    authApi.setToken(token);

    return authApi.post<IJwtTokenResponse>('/tokens/filter', {
      filter: ['']
    });
  }

  public redirectToAuthPage() {
    const target = encodeURIComponent(window.location.href);
    window.location.assign(`${authUiUrl}/?target=${target}`);
  }

  public signOut() {
    AmplifyAuth.signOut();
  }

  async init(): Promise<IAuthState | undefined> {
    let session;
    try {
      session = await AmplifyAuth.currentSession();
      if (!session) {
        return;
      }
      console.log(session);
    } catch (e) {
      console.log('error', e, typeof e);
      this.redirectToAuthPage();
      return;
    }

    const cognitoToken = session.getAccessToken().getJwtToken();
    if (!cognitoToken) {
      return;
    }

    const { email, sub } = session.getIdToken().decodePayload();
    const name = this.extractNameFromEmail(email);

    const dhcJwtResponse = await this.getAuthorizationToken(cognitoToken);
    if (!dhcJwtResponse) {
      return;
    }

    const { token } = dhcJwtResponse;

    storage.set('dhcToken', token);

    const parsedToken = this.parseToken(token);

    return {
      user: {
        id: sub,
        name,
        email
      },
      idToken: cognitoToken,
      dhcToken: dhcJwtResponse.token,
      parsedToken
    };
  }
}

export const auth = new Auth();
