import { Button, Classes, H3, Icon, Intent, IProps } from '@blueprintjs/core';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { KitService } from '../../../services/kits';
import {
  auditPaginationStateAtom,
  kitAuditStateAtom,
  selectedKitStateAtom
} from '../../../store';
import { IKitAudit, IPagination } from '../../../types';
import { AppToaster } from '../../App/toaster';
import { fetchPageNumbers } from '../../Pagination/PaginationUtils';
import { AuditPagination } from '../AuditPagination';
import './index.scss';

export const KitAudits: React.FunctionComponent<IProps> = (props) => {
  const [selectedKitState] = useRecoilState(selectedKitStateAtom);
  const [, setKitAuditState] = useRecoilState(kitAuditStateAtom);
  const [auditPaginationState, setAuditPaginationState] = useRecoilState(
    auditPaginationStateAtom
  );

  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };

  useEffect(() => {
    loadAuditPaginatedData([]);
    listKitAudits(selectedKitState.deviceId).then();
  }, [selectedKitState]);

  function sortBy(field: string, audits: IKitAudit[]) {
    let sortedAudits: IKitAudit[] = [];

    switch (field) {
      case 'kit-id': {
        if (audits[0].id > audits[audits.length - 1].id) {
          sortedAudits = audits.sort((a, b) => {
            if (a.id === null) return -1;
            if (b.id === null) return 1;
            return a.id.localeCompare(b.id);
          });
        } else {
          sortedAudits = audits.sort((b, a) => {
            if (a.id === null) return -1;
            if (b.id === null) return 1;
            return a.id.localeCompare(b.id);
          });
        }
        break;
      }
      case 'institution-id': {
        if (audits[0].institutionId > audits[audits.length - 1].institutionId) {
          sortedAudits = audits.sort((a, b) => {
            if (a.institutionId === null) return -1;
            if (b.institutionId === null) return 1;
            return a.institutionId.localeCompare(b.institutionId);
          });
        } else {
          sortedAudits = audits.sort((b, a) => {
            if (a.institutionId === null) return -1;
            if (b.institutionId === null) return 1;
            return a.institutionId.localeCompare(b.institutionId);
          });
        }
        break;
      }
      case 'component-id': {
        if (audits[0].componentId > audits[audits.length - 1].componentId) {
          sortedAudits = audits.sort((a, b) => {
            if (a.componentId === null) return -1;
            if (b.componentId === null) return 1;
            return a.componentId.localeCompare(b.componentId);
          });
        } else {
          sortedAudits = audits.sort((b, a) => {
            if (a.componentId === null) return -1;
            if (b.componentId === null) return 1;
            return a.componentId.localeCompare(b.componentId);
          });
        }
        break;
      }
      case 'component-type': {
        if (audits[0].componentType > audits[audits.length - 1].componentType) {
          sortedAudits = audits.sort((a, b) => {
            if (a.componentType === null) return -1;
            if (b.componentType === null) return 1;
            return a.componentType.localeCompare(b.componentType);
          });
        } else {
          sortedAudits = audits.sort((b, a) => {
            if (a.componentType === null) return -1;
            if (b.componentType === null) return 1;
            return a.componentType.localeCompare(b.componentType);
          });
        }
        break;
      }
      case 'action': {
        if (audits[0].action > audits[audits.length - 1].action) {
          sortedAudits = audits.sort((a, b) => {
            if (a.action === null) return -1;
            if (b.action === null) return 1;
            return a.action.localeCompare(b.action);
          });
        } else {
          sortedAudits = audits.sort((b, a) => {
            if (a.action === null) return -1;
            if (b.action === null) return 1;
            return a.action.localeCompare(b.action);
          });
        }
        break;
      }
      case 'action-timestamp': {
        if (
          audits[0].actionTimestamp > audits[audits.length - 1].actionTimestamp
        ) {
          sortedAudits = audits.sort((a, b) => {
            if (a.actionTimestamp === null) return -1;
            if (b.actionTimestamp === null) return 1;
            return a.actionTimestamp.localeCompare(b.actionTimestamp);
          });
        } else {
          sortedAudits = audits.sort((b, a) => {
            if (a.actionTimestamp === null) return -1;
            if (b.actionTimestamp === null) return 1;
            return a.actionTimestamp.localeCompare(b.actionTimestamp);
          });
        }
        break;
      }
    }

    const pagination: IPagination = {
      allItems: auditPaginationState.allItems,
      filteredItems: auditPaginationState.filteredItems,
      currentItems: sortedAudits,
      currentPage: auditPaginationState.currentPage,
      totalPages: auditPaginationState.totalPages,
      pageLimit: auditPaginationState.pageLimit,
      pageNeighbors: auditPaginationState.pageNeighbors,
      pageNumbers: auditPaginationState.pageNumbers
    };
    setAuditPaginationState(pagination);
  }

  async function listKitAudits(deviceId: string) {
    try {
      const audits = await KitService.listKitAudits(deviceId);
      if (!audits || !audits.length) {
        AppToaster.show({
          message: `Selected kit's audits are not available`,
          intent: Intent.DANGER
        });
        return;
      }
      setKitAuditState(audits);
      loadAuditPaginatedData(audits);
    } catch (error) {
      AppToaster.show({
        message: `Error while retrieving kit audit records - ${error}`,
        intent: Intent.DANGER
      });
    }
  }

  function loadAuditPaginatedData(
    audits: IKitAudit[],
    pageLimit: number = auditPaginationState.pageLimit
  ) {
    const filteredItems = audits.slice(0);
    const currentItems = audits.slice(0, pageLimit);
    const pageNumbers = fetchPageNumbers(
      Math.ceil(audits.length / pageLimit),
      1,
      1
    );
    const pagination: IPagination = {
      allItems: audits,
      filteredItems,
      currentItems: currentItems,
      currentPage: 1,
      totalPages: filteredItems.length / pageLimit,
      pageLimit: pageLimit,
      pageNeighbors: 1,
      pageNumbers: pageNumbers
    };
    setAuditPaginationState(pagination);
  }

  return (
    <>
      <H3>{`Kit [${selectedKitState.id}] Audit History`}</H3>
      <p className={Classes.RUNNING_TEXT}>Kit Audit History</p>

      <div className="filter-row">
        <span>Total audit records: {auditPaginationState.allItems.length}</span>
      </div>
      <div>
        <AuditPagination />
      </div>
      <table className="bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive audits-table">
        <thead>
          <tr>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'kit-id',
                    auditPaginationState.currentItems.slice() as IKitAudit[]
                  )
                }
              >
                <b>Kit ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'institution-id',
                    auditPaginationState.currentItems.slice() as IKitAudit[]
                  )
                }
              >
                <b>Institution ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'action',
                    auditPaginationState.currentItems.slice() as IKitAudit[]
                  )
                }
              >
                <b>Action</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'action-timestamp',
                    auditPaginationState.currentItems.slice() as IKitAudit[]
                  )
                }
              >
                <b>Action Timestamp</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'component-id',
                    auditPaginationState.currentItems.slice() as IKitAudit[]
                  )
                }
              >
                <b>Component ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'component-type',
                    auditPaginationState.currentItems.slice() as IKitAudit[]
                  )
                }
              >
                <b>Component Type</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button className="bp3-minimal">
                <b>Metadata</b>
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {(auditPaginationState.currentItems as IKitAudit[]).map(
            (audit, index) => {
              return (
                <tr key={index}>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {audit.id ? audit.id : '--'}
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {audit.institutionId ? audit.institutionId : '--'}
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {audit.action ? audit.action : '--'}
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {audit.actionTimestamp
                      ? new Intl.DateTimeFormat(
                          'en-US',
                          dateTimeOptions
                        ).format(new Date(audit.actionTimestamp))
                      : '--'}
                    <br />
                    <small>
                      (
                      {audit.actionTimestamp
                        ? Math.round(
                            (new Date().getTime() -
                              new Date(audit.actionTimestamp).getTime()) /
                              (1000 * 3600 * 24)
                          ) + ' days ago'
                        : '--'}
                      )
                    </small>
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {audit.componentId ? audit.componentId : '--'}
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {audit.componentType ? audit.componentType : '--'}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      width: '200px',
                      wordBreak: 'break-all'
                    }}
                  >
                    {audit.newMetadata
                      ? JSON.stringify(audit.newMetadata)
                      : '--'}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
};
