import React from 'react';
import './style.scss';
import { Callout, H1, H3 } from '@blueprintjs/core';
import { apiKeyStateAtom } from '../../store';
import { useRecoilState } from 'recoil';
import { Redirect } from 'react-router';
import { api, storage } from '../../services';

export default function (props: any) {
  const [apiKeyState, setApiKey] = useRecoilState(apiKeyStateAtom);

  let localApiKey = '';

  const onSignIn = () => {
    setApiKey(localApiKey);
    api.setApiKey(localApiKey);
    storage.set('dhcApiKey', localApiKey);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (typeof (value) === 'string') {
      localApiKey = event.target.value;
    }
  }

  const showSignIn = () => {
    return (
      <div>
        <Callout>
          <div>
            <H1 className="mt-5">DHC Logistics Adminstrator Interface</H1>
            <H3>Log in with API key</H3>
            <p>To access the administrator interface, enter your API key below. Your API key is available from the DHC cloud support team.</p>
          </div>

          <form className="bp3-input-group bp3-large">
            <input type="text" className="bp3-input" onChange={onChange} placeholder="Paste API key here..." />
            <button className="bp3-button bp3-intent-primary bp3-icon-arrow-right" onClick={() => onSignIn()}></button>
          </form>

          <div>{apiKeyState}</div>
        </Callout>
     </div>
    );
  };

  const showSignOut = () => {
    return <Redirect to={'/kits'}/>;
  };

  return (
    <div className="Login">
      <div>
        {
          apiKeyState ? showSignOut() : showSignIn()
        }
      </div>

    </div>
  );
};
