import {
  Button,
  Classes,
  H3,
  Icon,
  RadioGroup,
  Radio,
  Intent,
  Dialog,
  AnchorButton,
  FormGroup,
  InputGroup,
  Alert
} from '@blueprintjs/core';
import React, { FormEvent, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { kitsStateAtom } from '../../../store/kits-data';
import { kitsCountStateAtom } from '../../../store/kits-count';
import { kitsFilterStateAtom } from '../../../store/kits-filter';
import { prepareKitDialogStateAtom } from '../../../store/prepare-kit-dialog-state';
import { allocateKitDialogStateAtom } from '../../../store/allocate-kit-dialog-state';
import { IAlert, IKit, KitStatus } from '../../../types';
import './index.scss';
import { AppToaster } from '../../App/toaster';
import { paginationStateAtom } from '../../../store/pagination-data';
import { IPagination } from '../../../types/pagination';
import { Pagination } from '../../Pagination';
import { spinnerStateAtom } from '../../../store/spinner-data';
// import { Actions } from './Actions';
import { KitService } from '../../../services/kits';
import { LinkInstitution } from './LinkInstitution';
import { ActivateKit } from './ActivateKit';
import { UpdateKitComponents } from './UpdateKitComponents';
import { alertStateAtom, selectedKitStateAtom } from '../../../store';
import { fetchPageNumbers } from '../../Pagination/PaginationUtils';
import { kitAuditDialogStateAtom } from '../../../store/kit-audits-dialog-state';
import { KitAudits } from '../KitAudits';

export const Kits: React.FunctionComponent = () => {
  const [, setKitsState] = useRecoilState(kitsStateAtom);
  const [kitCountState, setKitCountState] = useRecoilState(kitsCountStateAtom);
  const [kitsFilterState, setKitsFilterState] =
    useRecoilState(kitsFilterStateAtom);

  const [selectedKitState, setSelectedKitState] =
    useRecoilState(selectedKitStateAtom);
  const [alertState, setAlertState] = useRecoilState(alertStateAtom);
  const [paginationState, setPaginationState] =
    useRecoilState(paginationStateAtom);
  const [prepareKitDialogState, setPrepareKitDialogState] = useRecoilState(
    prepareKitDialogStateAtom
  );
  const [allocateKitDialogState, setAllocateKitDialogState] = useRecoilState(
    allocateKitDialogStateAtom
  );
  const [, setSpinnerState] = useRecoilState(spinnerStateAtom);
  const [kitAuditDialogState, setKitAuditDialogState] = useRecoilState(
    kitAuditDialogStateAtom
  );

  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };
  const paginationInitialPageLimit = 100;

  let deviceId: string;
  let baseStationId: string;
  let simId: string;

  useEffect(() => {
    setSpinnerState({
      isOpen: true
    });
    listKits().then();
  }, []);

  async function onAlertConfirm() {
    const alert: IAlert = {
      isOpen: false,
      retireAction: false,
      kit: selectedKitState
    };

    if (
      [KitStatus.Activated].includes(selectedKitState.kitStatus as KitStatus)
    ) {
      await retireKit(alert.kit.deviceId);
    } else {
      await deactivateKit(alert.kit.deviceId);
    }

    // Close Alert
    setAlertState(alert);
  }

  async function retireKit(deviceId: string): Promise<void> {
    try {
      const res = await KitService.retireKit(deviceId);
      if (!res) {
        AppToaster.show({
          message: 'Error while retiring kit: Please try again',
          intent: Intent.DANGER
        });

        return;
      }
      AppToaster.show({
        message: 'Kit is deactivated and hardware are decommissioned',
        intent: Intent.SUCCESS
      });
      await listKits();
    } catch (error) {
      AppToaster.show({
        message: `Error while retiring kit: ${error}`,
        intent: Intent.DANGER
      });
    }
  }

  async function deactivateKit(deviceId: string): Promise<void> {
    try {
      const res = await KitService.deactivateKit(deviceId);
      if (!res) {
        AppToaster.show({
          message: 'Error while deactivating kit: Please try again',
          intent: Intent.DANGER
        });

        return;
      }
      AppToaster.show({
        message: 'Kit is deactivated',
        intent: Intent.SUCCESS
      });
      await listKits();
    } catch (error) {
      AppToaster.show({
        message: `Error while deactivating kit: ${error}`,
        intent: Intent.DANGER
      });
    }
  }

  function onAlertCancel() {
    const alert: IAlert = {
      isOpen: false,
      retireAction: false,
      kit: {} as IKit
    };
    setAlertState(alert);
  }

  // function handleActionClick(kit: IKit) {
  //   setSelectedKitState(kit);
  // }

  async function listKits() {
    try {
      const kits = await KitService.listKits();
      setSpinnerState({
        isOpen: false
      });
      if (!kits || !kits.length) {
        AppToaster.show({
          message: 'Error while loading kits',
          intent: Intent.DANGER
        });
        return;
      }
      setKitsState(kits);
      setKitCountState(kits.length);
      loadPagination(kits, kits, paginationInitialPageLimit);
    } catch (error) {
      setSpinnerState({
        isOpen: false
      });
      AppToaster.show({
        message: `Error while retrieving kits - ${error}`,
        intent: Intent.DANGER
      });
    }
  }

  async function prepareKit() {
    const { deviceId, baseStationId, simId } = prepareKitDialogState;
    if (!deviceId) {
      AppToaster.show({
        message: 'Error in preparing kit: Device Id must be non empty string',
        intent: Intent.DANGER
      });
      return;
    }
    if (!baseStationId) {
      AppToaster.show({
        message:
          'Error in preparing kit: Base station Id must be non empty string',
        intent: Intent.DANGER
      });
      return;
    }
    if (!simId) {
      AppToaster.show({
        message: 'Error in preparing kit: Sim Id must be non empty string',
        intent: Intent.DANGER
      });
      return;
    }
    const kit = {
      deviceId,
      baseStationId,
      simId
    };
    try {
      setSpinnerState({
        isOpen: true
      });
      const res = await KitService.createKit(kit);
      if (!res) {
        setSpinnerState({
          isOpen: false
        });
        AppToaster.show({
          message: 'Error while preparing kit: Try again',
          intent: Intent.DANGER
        });
        return;
      }
      setSelectedKitState(res as IKit);
      AppToaster.show({
        message: `Kit is prepared for device [${deviceId}], base station [${baseStationId}], and sim [${simId}]`,
        intent: Intent.SUCCESS
      });
      handleDialogClose();
      // openAllocateKit();
      await listKits();
    } catch (error) {
      setSpinnerState({
        isOpen: false
      });
      AppToaster.show({
        message: `Error while preparing kit - ${error}`,
        intent: Intent.DANGER
      });
    }
  }

  function loadPagination(
    allKits: IKit[],
    filteredKits: IKit[],
    pageLimit: number = paginationState.pageLimit
  ) {
    const currentItems = filteredKits.slice(0, pageLimit);
    const pageNumbers = fetchPageNumbers(
      Math.ceil(filteredKits.length / pageLimit),
      1,
      1
    );
    const pagination: IPagination = {
      allItems: allKits,
      filteredItems: filteredKits,
      currentItems: currentItems,
      currentPage: 1,
      totalPages: filteredKits.length / pageLimit,
      pageLimit: pageLimit,
      pageNeighbors: 1,
      pageNumbers: pageNumbers
    };
    setPaginationState(pagination);
  }

  function handleAuditDialogClose() {
    setKitAuditDialogState({
      ...kitAuditDialogState,
      ...{ isOpen: false, audits: [] }
    });
  }

  return (
    <div>
      <H3>Kits</H3>
      <p className={Classes.RUNNING_TEXT}>
        <strong>Pending:</strong> A kit showing up in our system via HealthLink,
        but which has some issue and is not ready for allocation.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        <strong>Ready for Institution:</strong> A fully assembled kit that has
        been provisioned to Pilot or Production and is ready for HealthLink to
        allocate to an institute.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        <strong>Ready for Patient:</strong> A fully assembled kit that has been
        allocated to a specific institute and may or may not have been shipped
        out.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        <strong>Active:</strong> A kit that has been deployed to a patient and
        is now in use.
      </p>

      {/* <Button
        onClick={openPrepareKit}
        className="bp3-intent-primary"
        icon="build"
      >
        Prepare kit
      </Button> */}
      <br />
      <br />

      <div className="filter-row">
        <RadioGroup
          onChange={filter}
          inline={true}
          selectedValue={kitsFilterState}
        >
          <Radio label="No filter" value="no_filter" title="Show all" />{' '}
          {/* show all kits */}
          <Radio
            label="Pending Kit"
            value="pending_new_base_station"
            title="Show empty base station rows only"
          />{' '}
          {/* show all pending kits */}
          <Radio
            label="Ready for Institution"
            value="ready_for_institution"
            title="Show All ready kits"
          />{' '}
          <Radio
            label="Ready for patient"
            value="ready_for_patient"
            title="Show all allocated kits"
          />{' '}
          {/* show empty patient field only */}
          <Radio
            label="Active"
            value="active"
            title="Show rows with device, base station, patient, and institution populated"
          />{' '}
          {/* show all 4 fields populated */}
        </RadioGroup>

        <span>Total kit count: {kitCountState}</span>
      </div>
      <div>
        <Pagination />
      </div>

      <table
        className="kits-table bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive"
        style={{ width: '100%' }}
      >
        <thead>
          <tr>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'kit-id',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Kit ID"
              >
                <b>Kit ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'status',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Status"
              >
                <b>Status</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'device-id',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Device ID"
              >
                <b>Device ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'base-station-id',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Base Station ID"
              >
                <b>Base Station ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'sim-id',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by SIM ID"
              >
                <b>SIM ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'patient-id',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Patient ID"
              >
                <b>Patient ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'institution-id',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Institution ID"
              >
                <b>Institution ID</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'created-at',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Created Date"
              >
                <b>Created Date</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            <th>
              <Button
                className="bp3-minimal"
                onClick={() =>
                  sortBy(
                    'updated-at',
                    paginationState.currentItems.slice() as IKit[]
                  )
                }
                title="Sort by Updated Date"
              >
                <b>Updated Date</b>
                <Icon icon="sort" className={Classes.ALIGN_RIGHT} />
              </Button>
            </th>
            {/* <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
              <b>Actions</b>
            </th> */}
          </tr>
        </thead>

        <tbody>
          {(paginationState.currentItems as IKit[]).map((kit, index) => {
            return (
              <tr key={index}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.id ? kit.id : '--'}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.kitStatus ? kit.kitStatus : '--'}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.deviceId ? kit.deviceId : '--'}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.baseStationId ? kit.baseStationId : '--'}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.simId ? kit.simId : '--'}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.patientId ? kit.patientId : '--'}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.institutionId ? kit.institutionId : '--'}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.createdAt
                    ? new Intl.DateTimeFormat('en-US', dateTimeOptions).format(
                        new Date(kit.createdAt)
                      )
                    : '--'}
                  <br />
                  <small>
                    (
                    {kit.createdAt
                      ? Math.round(
                          (new Date().getTime() -
                            new Date(kit.createdAt).getTime()) /
                            (1000 * 3600 * 24)
                        ) + ' days ago'
                      : '--'}
                    )
                  </small>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {kit.updatedAt
                    ? new Intl.DateTimeFormat('en-US', dateTimeOptions).format(
                        new Date(kit.updatedAt)
                      )
                    : '--'}
                  <br />
                  <small>
                    (
                    {kit.updatedAt
                      ? Math.round(
                          (new Date().getTime() -
                            new Date(kit.updatedAt).getTime()) /
                            (1000 * 3600 * 24)
                        ) + ' days ago'
                      : '--'}
                    )
                  </small>
                </td>
                {/* <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {
                    <div onClick={() => handleActionClick(kit)}>
                      <Actions />
                    </div>
                  }
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Dialog
        icon="build"
        onClose={handleDialogClose}
        title="Prepare kit"
        isOpen={prepareKitDialogState.isOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Prepare a kit by linking a device, base station, and sim. The kit
            will be allocated to institution by linking institution id and
            activated later by adding linkage the patient id.
          </p>
          <br />
          <b>
            <FormGroup
              helperText="Must exist in Devices table"
              label="Device ID"
              labelFor="device-id-input"
              labelInfo="(required)"
            >
              <InputGroup
                id="device-id-input"
                placeholder="ADI-0001"
                onChange={handleDeviceIdChange}
              />
            </FormGroup>

            <FormGroup
              helperText="Must exist in Base Stations table"
              label="Base Station ID"
              labelFor="base-station-id-input"
              labelInfo="(required)"
            >
              <InputGroup
                id="base-station-id-input"
                placeholder="ADI-BS-0001"
                onChange={handleBaseStationIdChange}
              />
            </FormGroup>
            <FormGroup
              helperText="Must exist in Sim table"
              label="Sim ID"
              labelFor="sim-id-input"
              labelInfo="(required)"
            >
              <InputGroup
                id="sim-id-input"
                placeholder="ADI-SIM-0001"
                onChange={handleSimIdChange}
              />
            </FormGroup>
          </b>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleDialogClose}>Close</Button>
            <AnchorButton
              intent={Intent.PRIMARY}
              onClick={() => {
                prepareKit();
              }}
            >
              Prepare kit
            </AnchorButton>
            <AnchorButton
              intent={Intent.PRIMARY}
              onClick={() => {
                openAllocateKit();
              }}
            >
              Link Institution
            </AnchorButton>
          </div>
        </div>
      </Dialog>

      {/* <LinkInstitution />
      <ActivateKit />
      <UpdateKitComponents /> */}

      <Dialog
        icon="history"
        onClose={handleAuditDialogClose}
        title="Kit Audits"
        isOpen={kitAuditDialogState.isOpen}
        style={{ width: 'auto' }}
      >
        <div className={Classes.DIALOG_BODY}>
          <KitAudits />
        </div>
      </Dialog>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText={
          alertState.retireAction
            ? 'Deactivate Kit & Decommission Hardware'
            : 'Deactivate Kit'
        }
        intent={Intent.DANGER}
        isOpen={alertState.isOpen}
        onCancel={onAlertCancel}
        onConfirm={onAlertConfirm}
      >
        <p>
          {alertState.retireAction
            ? `Are you sure you want to decommission all components of this kit? Once
            a component is decommissioned, it can never be included in a kit
            again. You will not be able to undo this action.`
            : `Are you sure you want to deactivate this kit? You will not be able to
            undo this action.`}
        </p>
      </Alert>
    </div>
  );

  async function openAllocateKit() {
    const deviceId = selectedKitState.deviceId;
    const id = selectedKitState.id;

    if (!deviceId || !id) {
      AppToaster.show({
        message: 'Kit must be prepared before allocating to institution',
        intent: Intent.DANGER
      });
      return;
    }
    setAllocateKitDialogState({
      ...allocateKitDialogState,
      deviceId,
      id,
      isOpen: true
    });
  }

  // async function openPrepareKit() {
  //   setPrepareKitDialogState({ ...prepareKitDialogState, isOpen: true });
  // }

  function handleDeviceIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    deviceId = event.currentTarget.value;
    setPrepareKitDialogState({ ...prepareKitDialogState, deviceId });
  }

  function handleBaseStationIdChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    baseStationId = event.currentTarget.value;
    setPrepareKitDialogState({ ...prepareKitDialogState, baseStationId });
  }

  function handleSimIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    simId = event.currentTarget.value;
    setPrepareKitDialogState({ ...prepareKitDialogState, simId });
  }

  function handleDialogClose() {
    const state = { ...prepareKitDialogState, isOpen: false };
    setPrepareKitDialogState(state);
  }

  function sortBy(field: string, kits: IKit[]) {
    let sortedKits: IKit[] = [];

    switch (field) {
      case 'kit-id': {
        if (kits[0].id > kits[kits.length - 1].id) {
          sortedKits = kits.sort((a, b) => {
            if (a.id === null) return -1;
            if (b.id === null) return 1;
            return a.id.localeCompare(b.id);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.id === null) return -1;
            if (b.id === null) return 1;
            return a.id.localeCompare(b.id);
          });
        }
        break;
      }
      case 'device-id': {
        if (kits[0].deviceId > kits[kits.length - 1].deviceId) {
          sortedKits = kits.sort((a, b) => {
            if (a.deviceId === null) return -1;
            if (b.deviceId === null) return 1;
            return a.deviceId.localeCompare(b.deviceId);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.deviceId === null) return -1;
            if (b.deviceId === null) return 1;
            return a.deviceId.localeCompare(b.deviceId);
          });
        }
        break;
      }
      case 'base-station-id': {
        if (kits[0].baseStationId > kits[kits.length - 1].baseStationId) {
          sortedKits = kits.sort((a, b) => {
            if (a.baseStationId === null) return -1;
            if (b.baseStationId === null) return 1;
            return a.baseStationId.localeCompare(b.baseStationId);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.baseStationId === null) return -1;
            if (b.baseStationId === null) return 1;
            return a.baseStationId.localeCompare(b.baseStationId);
          });
        }
        break;
      }
      case 'sim-id': {
        if (kits[0].simId > kits[kits.length - 1].simId) {
          sortedKits = kits.sort((a, b) => {
            if (a.simId === null) return -1;
            if (b.simId === null) return 1;
            return a.simId.localeCompare(b.simId);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.simId === null) return -1;
            if (b.simId === null) return 1;
            return a.simId.localeCompare(b.simId);
          });
        }
        break;
      }
      case 'patient-id': {
        if (kits[0].patientId > kits[kits.length - 1].patientId) {
          sortedKits = kits.sort((a, b) => {
            if (a.patientId === null) return -1;
            if (b.patientId === null) return 1;
            return a.patientId.localeCompare(b.patientId);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.patientId === null) return -1;
            if (b.patientId === null) return 1;
            return a.patientId.localeCompare(b.patientId);
          });
        }
        break;
      }
      case 'institution-id': {
        if (kits[0].institutionId > kits[kits.length - 1].institutionId) {
          sortedKits = kits.sort((a, b) => {
            if (a.institutionId === null) return -1;
            if (b.institutionId === null) return 1;
            return a.institutionId.localeCompare(b.institutionId);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.institutionId === null) return -1;
            if (b.institutionId === null) return 1;
            return a.institutionId.localeCompare(b.institutionId);
          });
        }
        break;
      }
      case 'status': {
        if (kits[0].kitStatus > kits[kits.length - 1].kitStatus) {
          sortedKits = kits.sort((a, b) => {
            if (a.kitStatus === null) return -1;
            if (b.kitStatus === null) return 1;
            return a.kitStatus.localeCompare(b.kitStatus);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.kitStatus === null) return -1;
            if (b.kitStatus === null) return 1;
            return a.kitStatus.localeCompare(b.kitStatus);
          });
        }
        break;
      }
      case 'created-at': {
        if (kits[0].createdAt > kits[kits.length - 1].createdAt) {
          sortedKits = kits.sort((a, b) => {
            if (a.createdAt === null) return -1;
            if (b.createdAt === null) return 1;
            return a.createdAt.localeCompare(b.createdAt);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.createdAt === null) return -1;
            if (b.createdAt === null) return 1;
            return a.createdAt.localeCompare(b.createdAt);
          });
        }
        break;
      }
      case 'updated-at': {
        if (kits[0].updatedAt > kits[kits.length - 1].updatedAt) {
          sortedKits = kits.sort((a, b) => {
            if (a.updatedAt === null) return -1;
            if (b.updatedAt === null) return 1;
            return a.updatedAt.localeCompare(b.updatedAt);
          });
        } else {
          sortedKits = kits.sort((b, a) => {
            if (a.updatedAt === null) return -1;
            if (b.updatedAt === null) return 1;
            return a.updatedAt.localeCompare(b.updatedAt);
          });
        }
        break;
      }
    }

    const pagination1: IPagination = {
      allItems: paginationState.allItems,
      filteredItems: paginationState.filteredItems,
      currentItems: sortedKits,
      currentPage: paginationState.currentPage,
      totalPages: paginationState.totalPages,
      pageLimit: paginationState.pageLimit,
      pageNeighbors: paginationState.pageNeighbors,
      pageNumbers: paginationState.pageNumbers
    };
    setPaginationState(pagination1);
  }

  function filter(event: FormEvent<HTMLInputElement>) {
    setKitsFilterState(event.currentTarget.value);
    let allKits: IKit[] = paginationState.allItems as IKit[];
    const filteredKits: IKit[] = allKits.filter((kit) => {
      if (event.currentTarget.value === 'active') {
        return kit.kitStatus === KitStatus.Activated;
      } else if (event.currentTarget.value === 'ready_for_institution') {
        return kit.kitStatus === KitStatus.Ready;
      } else if (event.currentTarget.value === 'ready_for_patient') {
        return kit.kitStatus === KitStatus.Allocated;
      } else if (event.currentTarget.value === 'pending_new_base_station') {
        return kit.kitStatus === KitStatus.NotReady;
      } else {
        return true;
      }
    });
    loadPagination(allKits, filteredKits);
  }
};
