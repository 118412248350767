import { atom } from 'recoil';
import { IAlert, IKit } from '../types';

export const alertStateAtom = atom({
  key: 'alert',
  default: {
    isOpen: false,
    retireAction: false,
    kit: {} as IKit
  } as IAlert
});
