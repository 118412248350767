import { atom } from 'recoil';
import { IRegisterSimDialog } from '../types';

export const registerSimDialogStateAtom = atom({
  key: 'registerSimDialogState',
  default: {
    iccid: '',
    mvno: '',
    dataProvider: 'UNKNOWN',
    isOpen: false
  } as IRegisterSimDialog
});