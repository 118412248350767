import React from 'react';
import ReactDOM from 'react-dom';
import './init-config';
import './index.scss';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { RecoilRoot } from 'recoil';

// MSAL imports
import { MsalProvider } from '@azure/msal-react';
import MsalService from './services/msal';
import { BrowserRouter } from 'react-router-dom';

const msalInstance = MsalService.getMsalInstance();
// Initialize msal active account
MsalService.initAccount();

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <App pca={msalInstance} />
        </MsalProvider>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
