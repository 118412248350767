import React, { useEffect } from 'react';
import './App.scss';
import { useHistory } from 'react-router-dom';
import Header from '../Header';
import { authStateAtom } from '../../store';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { apiWithToken, mdhApiWithToken, auth, storage } from '../../services';
import { Spinner } from '@blueprintjs/core';
import { spinnerStateAtom } from '../../store/spinner-data';
import Footer from '../Footer';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  IPublicClientApplication,
  RedirectRequest
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import ErrorComponent from '../Error/ErrorComponent';
import Loading from '../Loading/Loading';
import { IAuthState } from '../../types';
import CustomNavigationClient from '../../NavigationClient';
import AppRoutes from './AppRoutes';

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const setRecoilState = useSetRecoilState(authStateAtom);
  const resetRecoilState = useResetRecoilState(authStateAtom);
  const [spinnerState] = useRecoilState(spinnerStateAtom);

  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);
  const authRequest: RedirectRequest = {
    scopes: ['offline_access', 'openid']
  };

  pca.addEventCallback(async (event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account, idToken } = payload;
      if (account) {
        pca.setActiveAccount(account);
        const data = await auth.getAuthorizationToken(idToken);
        if (!data) {
          resetRecoilState();
          return false;
        }
        const { token } = data;
        const parsedToken = auth.parseToken(token);
        const { name, idTokenClaims, localAccountId } = account;
        const { email } = idTokenClaims as { email: string };
        const newState: IAuthState = {
          user: {
            id: localAccountId,
            name: name || '',
            email
          },
          idToken,
          dhcToken: token,
          parsedToken
        };
        setRecoilState(newState);
        if (newState.dhcToken) {
          apiWithToken.setToken(newState.dhcToken);
          mdhApiWithToken.setToken(newState.dhcToken);
        }
        storage.set('dhcToken', data.token);
      }
    }
  });

  useEffect(() => {
    const init = async () => {
      const account = pca.getActiveAccount() as AccountInfo;
      const request = {
        scopes: ['offline_access', 'openid'],
        account
      };
      if (account) {
        const { idToken } = await pca.acquireTokenSilent(request);
        const data = await auth.getAuthorizationToken(idToken);
        if (!data) {
          resetRecoilState();
          return false;
        }
        const { token } = data;
        const parsedToken = auth.parseToken(token);
        const { name, idTokenClaims, localAccountId } = account;
        const { email } = idTokenClaims as { email: string };
        const newState: IAuthState = {
          user: {
            id: localAccountId,
            name: name || '',
            email
          },
          idToken,
          dhcToken: token,
          parsedToken
        };
        setRecoilState(newState);
        if (newState.dhcToken) {
          apiWithToken.setToken(newState.dhcToken);
          mdhApiWithToken.setToken(newState.dhcToken);
        }
        storage.set('dhcToken', data.token);
      }
    };
    init().then();
  }, [setRecoilState, resetRecoilState, pca]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <div className="App">
        <Header />
        <div hidden={!spinnerState.isOpen} className={'loading'}>
          <Spinner size={Spinner.SIZE_LARGE} className={'loading'} />
        </div>
        <div className="Outlet">
          <AppRoutes />
        </div>
        <Footer />
      </div>
    </MsalAuthenticationTemplate>
  );
}

export default App;
