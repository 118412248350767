export class StorageImpl {
  private readonly storage: any;

  constructor(storage: any = undefined) {
    this.storage = storage || sessionStorage || localStorage || InMemoryStorage;
  }

  public get(key: string): any {
    const item = this.storage.getItem(key);
    if (!item) {
      return undefined;
    }
    return JSON.parse(item);
  }

  public set(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
    return this;
  }

  public unset(key: string) {
    this.storage.removeItem(key);
    return this;
  }

  public clear() {
    this.storage.clear();
    return this;
  }

  public getAll() {
    if (this.storage === InMemoryStorage) {
      return InMemoryStorage.getKeys();
    }
    return Object.keys(this.storage);
  }
}

export class InMemoryStorage {
  private static cortege: any = {};

  static getKeys() {
    return Object.keys(InMemoryStorage.cortege);
  }

  static setItem(key: string, value: any) {
    InMemoryStorage.cortege[key] = value;
  }

  static getItem(key: string): any {
    return InMemoryStorage.cortege[key];
  }

  static removeItem(key: string): any {
    // eslint-disable-next-line no-prototype-builtins
    if (InMemoryStorage.cortege.hasOwnProperty(key)) {
      delete InMemoryStorage.cortege[key];
    }
  }

  static clear() {
    InMemoryStorage.cortege = {};
  }
}

export const storage = new StorageImpl();
