import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { apiKeyStateAtom } from '../../store';
import { Kits } from '../Home/Kits';
import Login from '../Login';
import NotFound from '../NotFound';

import { useIsAuthenticated } from '@azure/msal-react';

type Props = {};

const AppRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route exact path="/login">
        <Login />
      </Route>
      <PrivateRoute path="/kits">
        <Kits />
      </PrivateRoute>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

function PrivateRoute({ children, ...rest }: any) {
  const isAuthenticated = useIsAuthenticated();
  const [apiKey] = useRecoilState(apiKeyStateAtom);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        apiKey && isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default AppRoutes;
