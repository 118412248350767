import {
  Configuration,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';

// Load configuration based on environment
const { clientId, redirectUri, authority, knownAuthority, cacheLocation } =
  window.CONFIG_ENV;

const configuration: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri,
    knownAuthorities: [knownAuthority]
  },
  cache: { cacheLocation, storeAuthStateInCookie: false }
};

export default class MsalService {
  private static instance: IPublicClientApplication =
    new PublicClientApplication(configuration);

  /**
   * Method to get MSAL instance
   */
  public static getMsalInstance(): IPublicClientApplication {
    return MsalService.instance;
  }

  /**
   * Initialize account
   */
  public static initAccount(): void {
    const accounts = this.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.instance.setActiveAccount(accounts[0]);
    }
  }
}
