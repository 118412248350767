import { atom } from 'recoil';
import { IKitAudit } from '../types';

export const kitAuditDialogStateAtom = atom({
  key: 'kitAuditsDialog',
  default: {
    isOpen: false,
    audits: [] as IKitAudit[]
  }
});
