import { atom } from 'recoil';
import { IComponentAudit } from '../types';

export const componentAuditDialogStateAtom = atom({
  key: 'componentAuditsDialog',
  default: {
    isOpen: false,
    audits: [] as IComponentAudit[]
  }
});
