import React, { Fragment } from 'react';
import './index.scss';
import { useRecoilState } from 'recoil';
import { auditPaginationStateAtom } from '../../../store';
import { IPagination } from '../../../types/pagination';
import { Button, ButtonGroup } from '@blueprintjs/core';
import {
  fetchPageNumbers,
  LEFT_PAGE,
  RIGHT_PAGE
} from '../../Pagination/PaginationUtils';

export const AuditPagination: React.FunctionComponent = () => {
  const [paginationState, setPaginationState] = useRecoilState(
    auditPaginationStateAtom
  );

  const gotoPage = (page: any) => {
    const currentPage = Math.max(
      0,
      Math.min(
        page,
        Math.ceil(
          paginationState.filteredItems.length / paginationState.pageLimit
        )
      )
    );
    const offset = (currentPage - 1) * paginationState.pageLimit;
    const currentItems = paginationState.filteredItems.slice(
      offset,
      offset + paginationState.pageLimit
    );
    const pageNumbers = fetchPageNumbers(
      Math.ceil(
        paginationState.filteredItems.length / paginationState.pageLimit
      ),
      currentPage,
      1
    );
    const pagination: IPagination = {
      allItems: paginationState.allItems,
      filteredItems: paginationState.filteredItems,
      currentItems: currentItems,
      currentPage: currentPage,
      totalPages: paginationState.totalPages,
      pageLimit: paginationState.pageLimit,
      pageNeighbors: paginationState.pageNeighbors,
      pageNumbers: pageNumbers
    };
    setPaginationState(pagination);
  };

  const handleClick = (page: any) => (evt: any) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt: any) => {
    evt.preventDefault();
    gotoPage(
      paginationState.currentPage - paginationState.pageNeighbors * 2 - 1
    );
  };

  const handleMoveRight = (evt: any) => {
    evt.preventDefault();
    gotoPage(
      paginationState.currentPage + paginationState.pageNeighbors * 2 + 1
    );
  };

  return (
    <Fragment>
      <nav aria-label="Countries Pagination">
        <ButtonGroup className="bp3-button-group .modifier">
          {paginationState.pageNumbers.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <Button key={index} onClick={handleMoveLeft}>
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </Button>
              );

            if (page === RIGHT_PAGE)
              return (
                <Button key={index} onClick={handleMoveRight}>
                  <span className="sr-only">Next</span>
                  <span aria-hidden="true">&raquo;</span>
                </Button>
              );

            return (
              <Button
                className={paginationState.currentPage === page ? 'active' : ''}
                key={index}
                onClick={handleClick(page)}
              >
                {page}
              </Button>
            );
          })}
        </ButtonGroup>
        <span> &nbsp; &nbsp;</span>
        <span>Number of rows per page: {paginationState.pageLimit}</span>
      </nav>
    </Fragment>
  );
};
