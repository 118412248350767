import { atom } from 'recoil';
import { IAllocateKitDialog } from '../types';

export const allocateKitDialogStateAtom = atom({
  key: 'allocateKitDialog',
  default: {
    id: '',
    deviceId: '',
    institutionId: '',
    isOpen: false
  } as IAllocateKitDialog
});
