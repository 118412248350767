import { atom } from 'recoil';
import { IActivateKitDialog } from '../types';

export const activateKitDialogStateAtom = atom({
  key: 'activateKitDialog',
  default: {
    patientId: '',
    isOpen: false
  } as IActivateKitDialog
});
