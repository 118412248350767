if (!window.CONFIG_ENV) {
  const {
    REACT_APP_AUTH_API_URL,
    REACT_APP_MDH_API_URL,
    REACT_APP_LOGISTICS_API_URL,
    REACT_APP_AWS_USER_POOL_ID,
    REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    REACT_APP_AWS_COGNITO_COOKIE_STORAGE_DOMAIN,
    REACT_APP_CLIENT_ID,
    REACT_APP_REDIRECT_URI,
    REACT_APP_AUTHORITY,
    REACT_APP_KNOWN_AUTHORITY,
    REACT_APP_CACHE_LOCATION
  } = process.env;

  // Config object that hold all environment variables
  window.CONFIG_ENV = {
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    domain: REACT_APP_AWS_COGNITO_COOKIE_STORAGE_DOMAIN,
    authApiUrl: REACT_APP_AUTH_API_URL,
    mdhApiUrl: REACT_APP_MDH_API_URL,
    logisticsApiUrl: REACT_APP_LOGISTICS_API_URL,
    clientId: REACT_APP_CLIENT_ID,
    redirectUri: REACT_APP_REDIRECT_URI,
    authority: REACT_APP_AUTHORITY,
    knownAuthority: REACT_APP_KNOWN_AUTHORITY,
    cacheLocation: REACT_APP_CACHE_LOCATION
  };
}

export {};
