import { atom } from 'recoil';
import { IRegisterBaseStationDialog } from '../types';

export const registerBaseStationDialogStateAtom = atom({
  key: 'registerBaseStationDialog',
  default: {
    isOpen: false,
    autoCSR: true
  } as IRegisterBaseStationDialog
});
