import {
  IAllocateKit,
  IDeactivateKitResponse,
  IKit,
  IKitAudit,
  IPrepareKitResponse,
  IUpdateKitComponents
} from '../types';
import { api, apiWithToken } from './api';

export class KitService {
  public static async listKits(): Promise<IKit[] | undefined> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .get(`/device-kits?itemsPerPage=999999`)
        .then((res: any) => resolve(res.items))
        .catch((error: any) => reject(error));
    });
  }

  public static async allocateKit(
    deviceId: string,
    institutionId: string
  ): Promise<IAllocateKit> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .put(`/device-kits/${deviceId}/customer-links`, {
          institutionId: institutionId
        })
        .then((res) => resolve(res))
        .catch((error: any) => reject(error));
    });
  }

  public static async createKit(kit: {
    [key: string]: string;
  }): Promise<IPrepareKitResponse> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .post(`/device-kits`, kit)
        .then((res) => resolve(res))
        .catch((error: any) => reject(error));
    });
  }

  public static async activateKit(
    deviceId: string,
    patientId: string
  ): Promise<IPrepareKitResponse> {
    return new Promise(async (resolve: any, reject: any) => {
      await apiWithToken
        .put(`/patients/${patientId}/devices`, { deviceId })
        .then((res) => resolve(res))
        .catch((error: any) => reject(error));
    });
  }

  public static async swapBaseStation(
    deviceId: string,
    oldBaseStationId: string,
    newBaseStationId: string
  ): Promise<IUpdateKitComponents> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .patch(`/device-kits/${deviceId}/base-station-links`, {
          oldBaseStationId,
          newBaseStationId
        })
        .then((res) => resolve(res))
        .catch((error: any) => reject(error));
    });
  }

  public static async swapSim(
    deviceId: string,
    oldSimId: string,
    newSimId: string
  ): Promise<IUpdateKitComponents> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .patch(`/device-kits/${deviceId}/sim-links`, {
          oldSimId,
          newSimId
        })
        .then((res) => resolve(res))
        .catch((error: any) => reject(error));
    });
  }

  public static async deactivateKit(
    deviceId: string
  ): Promise<IDeactivateKitResponse> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .delete(`/device-kits/${deviceId}`)
        .then((res) => resolve(res))
        .catch((error: any) => reject(error));
    });
  }

  public static async retireKit(
    deviceId: string
  ): Promise<IDeactivateKitResponse> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .delete(`/device-kits/${deviceId}/hardware-decommission`)
        .then((res) => resolve(res))
        .catch((error: any) => reject(error));
    });
  }

  public static async listKitAudits(
    deviceId: string
  ): Promise<IKitAudit[] | undefined> {
    return new Promise(async (resolve: any, reject: any) => {
      await api
        .get(`/device-kits/${deviceId}/audit-history?itemsPerPage=999999`)
        .then((res: any) => resolve(res.items as IKitAudit[]))
        .catch((error: any) => reject(error));
    });
  }
}
