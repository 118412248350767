export interface IKit {
  id: string;
  deviceId: string;
  baseStationId: string;
  simId: string;
  patientId: string;
  institutionId: string;
  kitStatus: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAllocateKit {
  deviceId: string;
  institutionId: string;
}

export interface IPrepareKitResponse {
  id: string;
  deviceId: string;
  baseStationId: string;
  simId: string;
}

export interface IActivateKit {
  patientId: string;
}

export interface IUpdateKitComponents {
  deviceId: string;
  baseStationId?: string;
  simId?: string;
}

export enum KitStatus {
  NotReady = 'NOT_READY',
  Ready = 'READY',
  Activated = 'ACTIVATED',
  Allocated = 'ALLOCATED',
  Deactivated = 'DEACTIVATED'
}

export interface IDeactivateKitResponse {
  deviceId: string;
}
