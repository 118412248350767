import { atom } from 'recoil';
import { IUpdateKitComponentsDialog } from '../types';

export const updateKitComponentsDialogStateAtom = atom({
  key: 'updateKitComponentsDialog',
  default: {
    deviceId: '',
    oldBaseStationId: '',
    oldSimId: '',
    newBaseStationId: '',
    newSimId: '',
    isOpen: false
  } as IUpdateKitComponentsDialog
});
