import React from 'react';
import { MsalAuthenticationResult } from '@azure/msal-react';
import './Error.scss';

const ErrorComponent: React.FunctionComponent<MsalAuthenticationResult> = ({
  error
}) => {
  return (
    <h4 className="error">
      An Error Occurred: {error ? error.errorCode : 'unknown error'}
    </h4>
  );
};

export default ErrorComponent;
