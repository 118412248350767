import React from 'react';
import { useRecoilState } from 'recoil';
import { apiKeyStateAtom } from '../../store';
import './index.scss';

export default function Footer(props: any) {
  const [apiKeyState] = useRecoilState(apiKeyStateAtom);

  return (
      <div className="footer">
        <div>
          <small>
            API key: { (apiKeyState !== undefined) ?
              (
                (apiKeyState.substring(0, 2) +
                '*************************' +
                apiKeyState.substring(apiKeyState.length - 2))
              ) : (
                '-'
              )}
            </small>
            <br/>
            <br/>

            <a href="#top">Back to top</a>
          </div>

      </div>
  )
}
