import React from 'react';
import './style.scss';
import { useLocation } from 'react-router-dom';

export default function NotFound(props: any) {
  let location = useLocation();

  return (
    <div className="NotFound">
      Not Found Location <code>{location.pathname}</code>
    </div>
  );
};
